<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 积分商城
 * @Date: 2020-12-29 15:24:27
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-21 20:26:44
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/integral.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="type" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <skeleton v-show="loading" type="order" />
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div v-if="!list.length && !loading" class="ordernull">
          <van-empty
            image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            description="暂无数据"
          />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in list" :key="index">
              <div class="sptitle">
                <div><img :src="item.splImg" alt=""><span>{{ item.splName }}</span></div>
                <div>{{ item.orderTypeValue }}</div>
              </div>
              <div class="spcenter" @click="Orderdetails(item)">
                <div><img :src="item.goodCover[0]" alt=""></div>
                <div class="spcenter_text">
                  <div>{{ item.goodName }}</div>
                  <div>
                    <p>¥{{ item.payPrice }}</p>
                    <p>*{{ item.goodNum }}</p>
                  </div>
                </div>
              </div>
              <div class="spfoot">
                <span
                  v-if="item.orderState == 2 || item.orderState == 5 || item.orderState == 4"
                  class="s st"
                  @click="logistics(item)"
                >查看物流</span>
                <span v-if="item.isMakesure == 1" class="s sc" @click="Orderdetails(item)">确认收货</span>
                <span v-if="item.isCanComment == 1" class="s sc" @click="evaluate(item)">去评价</span>
                <span v-if="item.orderState == 3" class="s st" @click="Delete(item)">删除订单</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Empty, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty)
import {
  integralOrderList, mallNewOrderDelete
} from '@/services/userApi'
import mescroll from '@/component/fresh_scroll/mescroll'
import skeleton from '@/component/skeleton/index'

export default {
  components: {
    mescroll,
    skeleton
  },
  data() {
    return {
      loading: true,
      type: 0,
      page: 1,
      pageSize: 10,
      list: [],
      tablist: [
        { name: '全部' },
        { name: '待付款' },
        { name: '待收货' },
        { name: '已取消' },
        { name: '已完成' }
      ],
      mescrollDown: {
        use: true,
        bottom: '0',
        top: '50px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      }
    }
  },
  created() {

  },
  methods: {
    upCallback(page, mescroll) {
      this.getinfo(page.num, page.size, (arr) => {
        if (page.num === 1) this.classList = []
        this.classList = this.classList.concat(arr)
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }, () => {
        mescroll.endErr()
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    getinfo(pageNum, pageSize, successCallback, errorCallback) {
      const prom = {
        orderType: this.type,
        page: this.page,
        pageSize: this.pageSize
      }
      integralOrderList(prom).then(res => {
        this.loading = true
        if (Number(res.code) === 200 && res.list.length > 0) {
          successCallback(res.list)
          this.page++
          this.loading = false
        } else {
          errorCallback(res.msg)
          this.loading = false
        }
      })
    },
    // 删除订单
    Delete(n) {
      Dialog.confirm({
        message: '确认删除此订单'
      }).then(() => {
        const prom = {
          orderNo: n.orderNo
        }
        mallNewOrderDelete(prom).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            this.mescroll.resetUpScroll()
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
        // on cancel
      })
    },
    tabkey(i) {
      this.loading = true
      this.page = 1
      this.list = []
      this.mescroll.resetUpScroll()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .ordernull {
    padding: 13px;
  }
}
</style>
